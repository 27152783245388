<template>
  <div>
    <template v-if="mhj">
      <div class="display-inline-block">
        <div class="margin-bottom-10 flex-row align-items-center justify-between">
          <div><span class="color-red">*</span>签名</div>
          <el-button type="mini" @click="clearSign">重新签名</el-button>
        </div>
        <div class="sign-area margin-bottom-20">
          <div style="position:absolute;width:100%;height:100%;top:0;left:0;" class="flex-row">
            <div class="flex-row justify-center align-items-center" style="flex:1;border-right: #aaa dotted 1px; font-size: 80px; color: #f0f0f0;">请</div>
            <div class="flex-row justify-center align-items-center" style="flex:1;border-right: #aaa dotted 1px; font-size: 80px; color: #f0f0f0;">签</div>
            <div class="flex-row justify-center align-items-center" style="flex:1; font-size: 80px; color: #f0f0f0;">名</div>
          </div>
          <vue-esign style="position:relative" :width="600" :height="200" ref="esign"></vue-esign>
        </div>
      </div>
    </template>
    
    <div class="margin-bottom-10">上传图片</div>
    <el-upload 
      ref="upload" 
      list-type="picture-card"
      :limit="10" 
      accept=".jpg,.gif,.png,.jpeg" 
      :multiple="true"
      action=""
      :on-change="handleFileChange"
      :before-remove="handleFileRemove" 
      :on-exceed="exceed"
      :auto-upload="false"
      >
        <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <div class="margin-top-20 margin-bottom-10">回执留言</div>
    <el-input v-model="message"></el-input>
    <div class="flex-row justify-end margin-top-10">
      <el-button type="primary" :loading="uploadLoading" @click="mhj?handleSignature():uploadFile()">提交回执</el-button>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Sign',
  components: {},
  props:{
    orderId:{default:''},
    mhj:{default:false}
  },
  data() {
    return {
      uploadLoading:false,
      fileList:[],
      signImg:'',
      message:''
    }
  },
  methods:{
    handleSignature() {
      // 处理签字数据
      this.$refs.esign.generate().then(res => {
        this.signImg = res
        this.uploadFile()
      }).catch(() => {
        this.$message({message: '没有签名信息',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      })
    },
    //清空签名
    clearSign () {
      this.$refs.esign.reset()
      this.signImg=''
    },
    // 上传发生变化钩子
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    // 删除之前钩子
    handleFileRemove(file, fileList) {
      this.fileList = fileList;
    },
    clearFiles(){
      this.$refs.upload.clearFiles()
      this.fileList=[]
    },
    exceed(){
      this.$message({message: '只能上传10张图片，请先删除已选文件',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
    },
   
    uploadFile(){
      this.uploadLoading=true
       // 创建新的数据对象
       let formData = new FormData();
      // 将上传的文件放到数据对象中
      // this.fileList.forEach(file => {
      //   formData.append('order_img', file.raw);
      // });
      formData.append('order_img',this.fileList.map(v=>v.raw));
      formData.append('order_no',this.orderId)
      formData.append('sign_img_str',this.signImg)
      formData.append('receipt_msg',this.message)
      // console.log("提交前",formData.getAll('order_img'));
      this.$instance.post('/order/post_order_receipt',formData,
        {
          timeout: 1000,
        } 
      ).then(res=>{
        this.uploadLoading=false
        if(res.data.code==0){
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)});
          this.successCallBack()
        }
      })
      .catch(()=>{
        this.uploadLoading=false
      })
    },
    successCallBack(){
      this.$emit('success',this.orderId)
    }
  },
  created(){
  
  },
  mounted(){

  },
  watch: {
   "orderId"(){
    this.$nextTick(()=>{
      this.mhj&&this.clearSign()
      this.clearFiles()
      this.message=''
    })
   }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.sign-area{
  position: relative;
  border: #aaa dotted 1px;
  width: 600px;
  height: 200px;
}
</style>
