<template>
  <div v-loading="loading">
    <div :class="{'result-bar-fixed':resultBarFixed}">
      <div class="safe-area flex-row align-items-center" style="height:50px">
        <div class="link border-right padding-right-10 margin-right-10 color-sub" @click="$router.go(-1)"><i class="el-icon-back"></i>返回</div>
        <span class="bold">订单号：{{detailData.order_no}}</span>
      </div>
    </div>
    <div style="height: 50px;" v-if="resultBarFixed"></div>
    
    <div class="safe-area">
      <div class="column">
        <el-descriptions title="订单信息" size="medium" :column="3" border>
            <el-descriptions-item label="订单编号">{{detailData.order_no}}</el-descriptions-item>
            <el-descriptions-item label="下单时间">{{detailData.create_time}}</el-descriptions-item>
            <el-descriptions-item label="订单状态">
              <div class="flex-row align-items-center">
                <div :class="{'color-red':detailData.order_state==1,'color-orange':detailData.order_state==2,'color-blue':detailData.order_state==3,'color-green':detailData.order_state==4,'color-sub':detailData.order_state==5}">
                  {{detailData.order_state==1?'待提取':detailData.order_state==2?'分拣中':detailData.order_state==3?'已出库':detailData.order_state==4?'已送达':'已取消'}}
                </div>  
              </div>
            </el-descriptions-item>

            <el-descriptions-item label="收货人信息">{{ detailData.shr }}</el-descriptions-item>
            <el-descriptions-item label="客户名称">{{ detailData.khmc }}</el-descriptions-item>
            <el-descriptions-item label="金额">
              <div><span>实付：</span>￥{{ detailData.sfje }}</div>
              <div class="font-size-12 color-sub">
                <span>商品合计：￥{{ detailData.zje }}</span>
                <span class="margin-left-10">运费：￥{{ detailData.yf }}</span>
                <span class="margin-left-10">优惠：￥{{ detailData.hyje }}</span>
              </div>
            </el-descriptions-item>

            <el-descriptions-item label="配送方式">
              <div class="flex-row align-items-center">
                <div>{{detailData.psfs}}</div>  
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="运费付款方式">{{detailData.yffkfs}}</el-descriptions-item>
            <el-descriptions-item label="发票">{{detailData.fplx}}<span class="color-sub margin-left-10">{{ detailData.fpshfs?'随货发送':'不随货发送' }}</span></el-descriptions-item>

            <el-descriptions-item label="是否合单">
              <div class="flex-row">
                <div>{{detailData.is_hd?'是':'否'}}</div>
                <div class="border-left padding-left-10 margin-left-10" v-if="detailData.is_hd">
                  <div class="color-sub">合单收货人：{{ detailData.hd_user_name }}</div>
                  <div class="color-sub">收货人电话：{{ detailData.hd_user_phone }}</div>
                </div>
              </div>
              
              
            </el-descriptions-item>
            <el-descriptions-item label="其他资料">{{detailData.qtzl}}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{detailData.zffs}}</el-descriptions-item>

            <el-descriptions-item label="留言" :span="3">{{detailData.khly}}</el-descriptions-item>
            <el-descriptions-item label="物流信息" :span="3">{{detailData.wl}}</el-descriptions-item>
        </el-descriptions>
        <div style="background: #f0f0f0;" class="flex-row align-items-center justify-end padding-10">
          <div v-if="detailData.order_state!=5&&detailData.psfs=='自提'" class="margin-left-20 link-text color-yellow">
            <el-button  type="primary" size="mini" round plain>查看自提码</el-button>
          </div>
          <div class="margin-left-20" v-if="detailData.order_state!=4&&detailData.order_state!=5">
            <el-button @click="SignShow=true" type="primary" size="mini" round>确认收货</el-button>
          </div>
          <div class="margin-left-20" v-if="detailData.order_state!=4">
            <el-button type="primary" size="mini" round>评价</el-button>
          </div>
        </div>
        <div class="margin-top-20">
          <div class="bold font-size-16 margin-bottom-10">商品清单</div>
          <el-table
            :data="detailData.product_list"
            size="medium"
            :show-header="false"
            border
            style="width: 100%"
          >
            <el-table-column label="商品信息"  min-width="200">
              <template slot-scope="props">
                <div class="flex-row link" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:props.row.spid,hd_id:props.row.hd_id})},blank:true})">
                  <div><el-image class="cart-item-pic border" :src="props.row.img_url" fit="contain">
                    <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                      <img src="@/assets/images/no-pic.png" height="40px"/>
                    </div>
                  </el-image></div>
                  <div class="flex-auto margin-left-10 line-height-small">
                    <div class="bold">{{props.row.spmch}}</div>
                    <div class="color-sub font-size-12">{{props.row.shpgg}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template slot-scope="props">单价：￥{{ props.row.sp_buy_jiage  | numFormat(2)}}</template>
            </el-table-column>
            <el-table-column label="数量">
              <template slot-scope="props">
                数量：{{props.row.sp_buy_sl}}
              </template>
            </el-table-column>
            <el-table-column label="金额" align="right">
              <template slot-scope="props">金额：<span class="color-orange">￥{{ (props.row.sp_buy_jiage * props.row.sp_buy_sl) | numFormat(2)}}</span></template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" title="确认收货"  :closeOnClickModal="false" :visible.sync="SignShow" :append-to-body="true" width="980px" top="10vh">
      <div>
        <Sign :orderId="detailData.order_no" :mhj="(detailData.product_list||[]).some(v=>v.is_mhj)" @success="getDetail();SignShow=false;"></Sign>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import Sign from "@/components/Sign";
export default {
  components: {Sign },
  name: 'Home',
  data() {
    return {
      crypto,
      resultBarFixed:false,
      bottomResultBarFixed:false,
      loading:false,
      detailData:{},
      SignShow:false
    }
  },
  computed:{
    q(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        console.log(q)
        return q
      } catch (error) {
        return {}
      }
    },
  },
  methods:{
    handleScroll(){
      if(document.documentElement.scrollTop>120){
        this.resultBarFixed=true
      }else{
        this.resultBarFixed=false
      }
    },
    //获取详情
    getDetail(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        order_id:this.q.order_id,
      }
      this.$instance.get('/order/get_order_info',{params})
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.detailData=res.data.data
        }else{
          this.detailData={}
        }
      })
      .catch(()=> {
        this.loading=false
        this.detailData={}
      }); 
    },
    
  },
  created(){
    if(this.q.order_id){
      this.getDetail()
    }
  },
  mounted(){ 
    document.addEventListener('scroll', this.handleScroll)
    this.$nextTick(()=>{
      this.handleScroll()
    })
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .result-bar-fixed{
    position: fixed;
    left: 0;
    right: 0;
    top: 40px;
    background: #f7f7f7;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  }
  .cart-item-pic{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
